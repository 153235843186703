import React, { useState, useEffect, useCallback } from 'react';
import { graphql } from 'gatsby';
import { useIntl, Link } from 'gatsby-plugin-intl';
import { Nav, NavItem, NavLink, Row, Col, Button } from 'reactstrap';
import TextLoop from 'react-text-loop';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { Layout, SEO, PostCard, EventCard, Image } from '../components';
import messages from '../messages/home';
import filterByLanguage from '../utils/filterByLangage';
import nextArrow from '../assets/images/next-arrow.png';
import prevArrow from '../assets/images/prev-arrow.png';
import sliderBgAfter from '../assets/images/connected-dots.png';
import afcro from '../assets/images/african-crossraods-blue.png';
import sectwo from '../assets/images/section-two-side-image.png';
import seeAllArrow from '../assets/images/arrow-right.png';
import mIg from '../assets/images/member-ig.png';
import mIn from '../assets/images/member-in.png';
import mTw from '../assets/images/member-tw.png';
import mFb from '../assets/images/member-fb.png';
import partner1 from '../assets/images/MFA.png';
import partner2 from '../assets/images/SIDA.png';
import useWindowDimensions from '../utils/useWindowDimensions';

const IndexPage = ({
  data: {
    carouselPosts: { group: carouselPosts },
    latestUpdates: { group: latestUpdates },
    latestEvents: { group: latestEvents },
    latestArticles: { group: latestArticles },
    featuredMembers: { edges: featuredMembers }
  }
}) => {
  const intl = useIntl();

  const { width } = useWindowDimensions();
  const smallLimit = 1200;
  const extraSmallLimit = 576;
  const [isSmallScreen, setIsSmallScreen] = useState(width < smallLimit);
  const [isExtraSmallScreen, setIsExtraSmallScreen] = useState(
    width < extraSmallLimit
  );
  const [partnersSliderSettings, setPartnersSliderSettings] = useState({
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    lazyLoad: true,
    arrows: false
  });
  const [sliceLimit, setSliceLimit] = useState(4);

  useEffect(() => {
    setIsSmallScreen(width < smallLimit);
    setIsExtraSmallScreen(width < extraSmallLimit);
    if (width < 576) {
      setPartnersSliderSettings({ ...partnersSliderSettings, slidesToShow: 1 });
    } else if (width < 992) {
      setPartnersSliderSettings({ ...partnersSliderSettings, slidesToShow: 2 });
    } else if (width < 1200) {
      setPartnersSliderSettings({ ...partnersSliderSettings, slidesToShow: 2 });
    } else {
      setPartnersSliderSettings({ ...partnersSliderSettings, slidesToShow: 2 });
    }
    if (width <= 1200 && width >= 992) {
      setSliceLimit(3);
    } else {
      setSliceLimit(4);
    }
  }, [width]);

  const { locale } = intl;
  const [activeTab, setActiveTab] = useState('1');
  const [selectedMember, setSelectedMember] = useState(0);
  const [featuredMember, setFeaturedMember] = useState(featuredMembers[0].node);
  const [fade, setFade] = useState(false);
  useEffect(() => {
    setFeaturedMember(featuredMembers[selectedMember].node);
  }, [selectedMember]);

  const toggleTab = useCallback(
    tab => {
      if (activeTab !== tab) {
        setActiveTab(tab);
      }
    },
    [activeTab]
  );

  const HeroSliderArrow = props => {
    const { onClick, previous } = props;
    return (
      <Button
        className={`hero-arrow-btn hero-${previous ? 'previous' : 'next'}-btn`}
        onClick={onClick}
      >
        <Image
          disableLoadAnimation
          src={previous ? prevArrow : nextArrow}
          alt={previous ? 'previous' : 'next'}
        />
      </Button>
    );
  };

  const MemberSliderArrow = props => {
    const { onClick, previous } = props;
    return (
      <Button
        className={`highlighted-member-btn highlighted-member-${
          previous ? 'previous' : 'next'
        }-btn`}
        onClick={onClick}
      >
        <Image
          disableLoadAnimation
          src={previous ? prevArrow : nextArrow}
          alt={previous ? 'previous' : 'next'}
        />
      </Button>
    );
  };

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    lazyLoad: true,
    nextArrow: <HeroSliderArrow />,
    prevArrow: <HeroSliderArrow previous />
  };
  const memberSliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    fade: true,
    lazyLoad: true,
    beforeChange: () => setFade(false),
    afterChange: el => {
      setSelectedMember(el);
      setFade(true);
    },
    nextArrow: <MemberSliderArrow />,
    prevArrow: <MemberSliderArrow previous />
  };

  return (
    <Layout background={false} fluid noWrap={false}>
      <SEO title={intl.formatMessage(messages.home)} />
      <Row className="pb-5">
        {!isSmallScreen && (
          <Col xs="4" className="custom-hero-padding">
            <div className="h-100 d-flex align-items-end">
              <div className="vertical-heading">#crossroaders</div>
              <div className="red-big-heading">
                <div>AFRI</div>
                <div>CAN</div>
                <div>CROSS</div>
                <TextLoop mask noWrap={false}>
                  <div>ROADS</div>
                  <div>STORIES</div>
                  <div>PATHS</div>
                  <div>IDEAS</div>
                </TextLoop>
              </div>
            </div>
          </Col>
        )}
        <Col
          md={{ size: isSmallScreen ? '12' : '8' }}
          className={`${isSmallScreen ? 'p-0' : ''}`}
        >
          <div
            className="bg-enabled custom-hero-padding"
            style={{ backgroundImage: `url(${sliderBgAfter})` }}
          >
            <Slider className="pb-4" {...sliderSettings}>
              {filterByLanguage(carouselPosts, locale).map(el => (
                <div key={el.node.id} className="main-slider-slide-container">
                  <div
                    className="hero-silder-slide-bg"
                    style={{
                      backgroundImage: `url(${
                        el.node.featuredImage?.node.localFile.publicURL ||
                        el.node.featuredImage?.node.sourceUrl
                      })`
                    }}
                  />
                  <div className="hero-silder-slide-content d-flex align-items-end pl-5 pb-5">
                    <div>
                      <div
                        className={`hero-slider-slide-title mb-4 ${
                          isExtraSmallScreen ? 'small-screen' : ''
                        }`}
                        dangerouslySetInnerHTML={{ __html: el.node.title }}
                      />
                      <div
                        className={`hero-slider-slide-excerpt mb-5 ${
                          isExtraSmallScreen ? 'small-screen' : ''
                        }`}
                        dangerouslySetInnerHTML={{ __html: el.node.excerpt }}
                      />
                      <Link
                        to={`/${
                          el.node.author.slug === 'admin' ? 'update' : 'article'
                        }/${el.node.slug}`}
                      >
                        <Button
                          size="lg"
                          className="mb-5 hero-slider-slide-button"
                        >
                          {intl.formatMessage(messages.knowMore)}
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </Col>
        {isSmallScreen && (
          <Col xs="12" className="custom-hero-padding small-screen">
            <div className="h-100 d-flex align-items-end">
              <div className="vertical-heading small-screen">#crossroaders</div>
              <div className="red-big-heading small-screen">
                <div>AFRI</div>
                <div>CAN</div>
                <div>CROSS</div>
                <TextLoop mask noWrap={false}>
                  <div>ROADS</div>
                  <div>STORIES</div>
                  <div>PATHS</div>
                  <div>IDEAS</div>
                </TextLoop>
              </div>
            </div>
          </Col>
        )}
      </Row>
      <Row>
        <Col
          md="4"
          className="pl-5 py-5 d-flex flex-column justify-content-between presentation-section-left-wrapper"
        >
          <Image
            src={afcro}
            alt="africancrossroads"
            width="100%"
            className="afc-blue-logo"
          />
          <div className="a-c-community-pes-text my-5">
            {intl.formatMessage(messages.sectionTwo)}
          </div>
          <div>
            <Link to="/about">
              <Button className="section-two-button" size="lg">
                {intl.formatMessage(messages.knowMore)}
              </Button>
            </Link>
          </div>
        </Col>
        <Col md="8" className="text-center py-5">
          <Image
            src={sectwo}
            alt="African Crossroads is a community of future-oriented African thinkers and doers"
            className="section-two-image"
            width="100%"
          />
        </Col>
      </Row>
      <Row className="mt-5 pt-5">
        <Col xs="12">
          <div className="activities-tabs-and-search mb-5 d-flex justify-content-between">
            <Nav tabs className="activities-tabs">
              <NavItem>
                <NavLink
                  active={activeTab === '1'}
                  onClick={() => {
                    toggleTab('1');
                  }}
                  className="home-nav-link"
                >
                  {intl.formatMessage(messages.updates)}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={activeTab === '2'}
                  onClick={() => {
                    toggleTab('2');
                  }}
                  className="home-nav-link"
                >
                  {intl.formatMessage(messages.events)}
                </NavLink>
              </NavItem>
            </Nav>
            <Link to="/activities">
              <Button
                color="link"
                className="updates-events-see-all-button d-flex"
              >
                {activeTab === '1'
                  ? intl.formatMessage(messages.seeAllUpdates)
                  : intl.formatMessage(messages.seeAllEvents)}
                <Image src={seeAllArrow} alt="see all" className="ml-3" />
              </Button>
            </Link>
          </div>
        </Col>
      </Row>
      <Row xl="4" lg="3" md="2" xs="1">
        {(activeTab === '1' && (
          <>
            {filterByLanguage(latestUpdates, locale)
              .slice(0, sliceLimit)
              .map(({ node: update }) => (
                <PostCard post={update} key={update.id} />
              ))}
          </>
        )) || (
          <>
            {filterByLanguage(latestEvents, locale)
              .slice(0, sliceLimit)
              .map(({ node: event }) => (
                <EventCard event={event} size="3" />
              ))}
          </>
        )}
      </Row>
      <Row>
        {!isSmallScreen && (
          <Col md="5">
            <div className="d-flex align-items-start h-100">
              <div className="vertical-hightlight-text">
                {intl.formatMessage(messages.highlights)}
              </div>
              <div>
                <div
                  className={`highlighted-member-name mb-4 ${
                    fade ? 'fade-in-4' : ''
                  }`}
                >
                  {featuredMember.name}
                </div>
                <div
                  className={`highlighted-member-description ${
                    fade ? 'fade-in-4' : ''
                  }`}
                >
                  {featuredMember.description?.length > 254
                    ? `${featuredMember.description?.substring(0, 254)}...`
                    : featuredMember.description}
                </div>
                <div>
                  <div className="d-flex">
                    {featuredMember.memberFields.instagram && (
                      <a
                        href={featuredMember.memberFields.instagram}
                        target="blank"
                      >
                        <Image src={mIg} alt="ig" className="mr-2" />
                      </a>
                    )}
                    {featuredMember.memberFields.linkedin && (
                      <a
                        href={featuredMember.memberFields.linkedin}
                        target="blank"
                      >
                        <Image src={mIn} alt="in" className="mr-2" />
                      </a>
                    )}
                    {featuredMember.memberFields.twitter && (
                      <a
                        href={featuredMember.memberFields.twitter}
                        target="blank"
                      >
                        <Image src={mTw} alt="tw" className="mr-2" />
                      </a>
                    )}
                    {featuredMember.memberFields.facebook && (
                      <a
                        href={featuredMember.memberFields.facebook}
                        target="blank"
                      >
                        <Image src={mFb} alt="fb" />
                      </a>
                    )}
                  </div>
                  <Link
                    to="/community"
                    className="d-flex mt-4 pl-2 highlighted-members-see-all"
                  >
                    {intl.formatMessage(messages.seeAllMembers)}
                    <Image src={seeAllArrow} alt="see all" className="ml-3" />
                  </Link>
                </div>
              </div>
            </div>
          </Col>
        )}
        <Col xs={isSmallScreen ? '12' : '7'}>
          {isSmallScreen && (
            <div className="horizontal-hightlight-text">
              {intl.formatMessage(messages.highlights)}
            </div>
          )}
          <div className="w-100 h-100 d-flex justify-content-center">
            <Slider
              className="pb-4 h-100 slider-width-limit"
              {...memberSliderSettings}
            >
              {featuredMembers.map(el => (
                <div key={el.node.id}>
                  <Link to={`/member/${el.node.slug}`}>
                    <div
                      className={`highlighted-member-slider-image ${
                        isSmallScreen ? 'small-screen mt-2' : 'mt-5'
                      }`}
                      style={{
                        backgroundImage: `url(${
                          el.node.memberFields.memberPicture?.localFile
                            ?.publicURL ||
                          el.node.memberFields.memberPicture?.sourceUrl
                        })`
                      }}
                    />
                  </Link>
                </div>
              ))}
            </Slider>
          </div>
        </Col>
        {isSmallScreen && (
          <Col xs="12" className="highlith-content-custom-small-screen-padding">
            <div className="mt-4">
              <div
                className={`highlighted-member-name-small mb-2 ${
                  fade ? 'fade-in-4' : ''
                }`}
              >
                {featuredMember.name}
              </div>
              <div
                className={`highlighted-member-description-small mb-2 ${
                  fade ? 'fade-in-4' : ''
                }`}
              >
                {featuredMember.description?.length > 254
                  ? `${featuredMember.description?.substring(0, 254)}...`
                  : featuredMember.description}
              </div>
              <div>
                <div className="d-flex mt-3">
                  {featuredMember.memberFields.instagram && (
                    <a
                      href={featuredMember.memberFields.instagram}
                      target="blank"
                    >
                      <Image src={mIg} alt="ig" className="mr-2" width="24" />
                    </a>
                  )}
                  {featuredMember.memberFields.linkedin && (
                    <a
                      href={featuredMember.memberFields.linkedin}
                      target="blank"
                    >
                      <Image src={mIn} alt="in" className="mr-2" width="24" />
                    </a>
                  )}
                  {featuredMember.memberFields.twitter && (
                    <a
                      href={featuredMember.memberFields.twitter}
                      target="blank"
                    >
                      <Image src={mTw} alt="tw" className="mr-2" width="24" />
                    </a>
                  )}
                  {featuredMember.memberFields.facebook && (
                    <a
                      href={featuredMember.memberFields.facebook}
                      target="blank"
                    >
                      <Image src={mFb} alt="fb" width="24" />
                    </a>
                  )}
                </div>
                <Link
                  to="/community"
                  className="d-flex mt-2 highlighted-members-see-all-small"
                >
                  {intl.formatMessage(messages.seeAllMembers)}
                  <Image
                    src={seeAllArrow}
                    alt="see all"
                    className="ml-3"
                    height="14"
                  />
                </Link>
              </div>
            </div>
          </Col>
        )}
      </Row>
      <Row className="mt-5 pt-5">
        <Col xs="12">
          <div className="activities-tabs-and-search mb-5 d-flex justify-content-between">
            <Nav tabs className="activities-tabs">
              <NavItem>
                <NavLink active className="home-nav-link">
                  {intl.formatMessage(messages.topArticles)}
                </NavLink>
              </NavItem>
            </Nav>
            <Link to="/activities">
              <Button
                color="link"
                className="updates-events-see-all-button d-flex"
              >
                {intl.formatMessage(messages.seeAllArticles)}
                <Image src={seeAllArrow} alt="see all" className="ml-3" />
              </Button>
            </Link>
          </div>
        </Col>
      </Row>
      <Row xl="4" lg="3" md="2" xs="1">
        {filterByLanguage(latestArticles, locale)
          .slice(0, sliceLimit)
          .map(({ node: update }) => (
            <PostCard post={update} type="article" key={update.id} />
          ))}
      </Row>
      <Row className="mt-5 pt-5">
        <Col xs="12">
          <div className="activities-tabs-and-search mb-5 d-flex justify-content-between">
            <Nav tabs className="activities-tabs">
              <NavItem>
                <NavLink active className="home-nav-link">
                  {intl.formatMessage(messages.partners)}
                </NavLink>
              </NavItem>
            </Nav>
            <Link to="/partners">
              <Button
                color="link"
                className="updates-events-see-all-button d-flex"
              >
                {intl.formatMessage(messages.seeAllPartners)}
                <Image src={seeAllArrow} alt="see all" className="ml-3" />
              </Button>
            </Link>
          </div>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col>
          <Slider {...partnersSliderSettings} className="partners-slider">
            <div>
              <div className="d-flex align-items-center justify-content-center partners-slide">
                <Image src={partner1} alt="partner" />
              </div>
            </div>
            <div>
              <div className="d-flex align-items-center justify-content-center partners-slide">
                <Image src={partner2} alt="partner" />
              </div>
            </div>
          </Slider>
        </Col>
      </Row>
    </Layout>
  );
};

export default IndexPage;
export const query = graphql`
  {
    latestUpdates: allWpPost(
      filter: { author: { node: { slug: { eq: "admin" } } } }
      sort: { fields: [date, language___slug], order: DESC }
    ) {
      group(field: language___slug) {
        edges {
          node {
            id
            slug
            title
            dateAgoFr: date(fromNow: true, locale: "fr")
            dateAgoEn: date(fromNow: true, locale: "en")
            date(formatString: "D/MM/YYYY")
            excerpt
            tags {
              nodes {
                id
                slug
                name
              }
            }
            categories {
              nodes {
                id
                slug
                name
              }
            }
            author {
              node {
                id
                slug
                name
                avatar {
                  url
                }
              }
            }
            featuredImage {
              node {
                id
                sourceUrl
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    }

    carouselPosts: allWpPost(
      sort: { fields: [date, language___slug], order: DESC }
      limit: 8
    ) {
      group(field: language___slug) {
        edges {
          node {
            id
            slug
            title
            dateAgoFr: date(fromNow: true, locale: "fr")
            dateAgoEn: date(fromNow: true, locale: "en")
            date(formatString: "D/MM/YYYY")
            excerpt
            tags {
              nodes {
                id
                slug
                name
              }
            }
            categories {
              nodes {
                id
                slug
                name
              }
            }
            author {
              node {
                id
                slug
                name
                avatar {
                  url
                }
              }
            }
            featuredImage {
              node {
                id
                sourceUrl
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    }

    latestEvents: allWpEvent(
      sort: { fields: [date, language___slug], order: DESC }
    ) {
      group(field: language___slug) {
        edges {
          node {
            id
            content
            title
            slug
            dateAgoFr: date(fromNow: true, locale: "fr")
            dateAgoEn: date(fromNow: true, locale: "en")
            date(formatString: "D/MM/YYYY")
            excerpt
            tags {
              nodes {
                id
                slug
                name
              }
            }
            categories {
              nodes {
                id
                slug
                name
              }
            }
            author {
              node {
                id
                slug
                name
                avatar {
                  url
                }
              }
            }
            featuredImage {
              node {
                id
                sourceUrl
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    }

    latestArticles: allWpPost(
      filter: { author: { node: { slug: { ne: "admin" } } } }
      sort: { fields: [date, language___slug], order: DESC }
    ) {
      group(field: language___slug) {
        edges {
          node {
            id
            slug
            title
            content
            dateAgoFr: date(fromNow: true, locale: "fr")
            dateAgoEn: date(fromNow: true, locale: "en")
            date(formatString: "D/MM/YYYY")
            excerpt
            tags {
              nodes {
                id
                slug
                name
              }
            }
            categories {
              nodes {
                id
                slug
                name
              }
            }
            author {
              node {
                id
                slug
                name
                avatar {
                  url
                }
              }
            }
            featuredImage {
              node {
                id
                sourceUrl
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    }

    featuredMembers: allWpUser(
      limit: 4
      filter: {
        slug: { ne: "admin" }
        memberFields: { highlightedMember: { eq: true } }
      }
    ) {
      edges {
        node {
          id
          slug
          name
          description
          memberFields {
            facebook
            instagram
            linkedin
            twitter
            memberPicture {
              sourceUrl
              localFile {
                publicURL
              }
            }

            memberSince
          }
        }
      }
    }
  }
`;
