import { defineMessages } from 'react-intl';

export const scope = 'home';

export default defineMessages({
  knowMore: {
    id: `${scope}.knowMore`,
    defaultMessage: 'Know More'
  },
  sectionTwo: {
    id: `${scope}.sectionTwo`,
    defaultMessage:
      'African Crossroads is a community of future-oriented African thinkers and doers'
  },
  updates: {
    id: `${scope}.updates`,
    defaultMessage: 'Updates'
  },
  events: {
    id: `${scope}.events`,
    defaultMessage: 'Events'
  },
  seeAllUpdates: {
    id: `${scope}.seeAllUpdates`,
    defaultMessage: 'See all updates'
  },
  seeAllEvents: {
    id: `${scope}.seeAllEvents`,
    defaultMessage: 'See all events'
  },
  highlights: {
    id: `${scope}.highlights`,
    defaultMessage: 'Hightlights'
  },
  seeAllMembers: {
    id: `${scope}.seeAllMembers`,
    defaultMessage: 'See all members'
  },
  seeAllArticles: {
    id: `${scope}.seeAllArticles`,
    defaultMessage: 'See all articles'
  },
  topArticles: {
    id: `${scope}.topArticles`,
    defaultMessage: 'Top Articles'
  },
  partners: {
    id: `${scope}.partners`,
    defaultMessage: 'Partners'
  },
  seeAllPartners: {
    id: `${scope}.seeAllPartners`,
    defaultMessage: 'See all partners'
  },
  home: {
    id: `${scope}.home`,
    defaultMessage: 'Home'
  }
});
